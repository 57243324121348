import Vue from 'vue'

const state = {
	token: null,
	fetchedForm: null,
}

const mutations = {
	setToken: (state, data) => (state.token = data),
	setForm: (state, data) => (state.fetchedForm = data),
}

const getters = {
	getFetchedForm: (state) => state.fetchedForm,
}

const actions = {
	async validateToken({ state, commit }) {
		commit('general/setshowMessageStatus', true, { root: true })
		commit('general/setMessage', 'Buscando formulario', { root: true })
		commit('general/setLoadingStatus', true, { root: true })
		await Vue.axios
			.post('tokens/validate', { token: state.token })
			.then((response) => {
				commit('general/setLoadingStatus', false, { root: true })
				commit('general/setshowMessageStatus', false, { root: true })
				commit('setForm', response.data)
			})
			.catch((error) => {
				commit(
					'general/setMessage',
					'No es posible contestar la encuesta solicitada ya que esta ha expirado o no fue posible encontrala',
					{
						root: true,
					},
				)
				commit('general/setLoadingStatus', false, { root: true })
				console.log(error)
			})
	},
	async saveAnswers({ state, commit }, data) {
		commit('general/setMessage', 'Registrando formulario', { root: true })
		commit('general/setLoadingStatus', true, { root: true })
		commit('general/setshowMessageStatus', true, { root: true })
		await Vue.axios
			.post('answers', { token: state.token, ...data })
			.then(() => {
				commit(
					'general/setMessage',
					'Formulario registrado con éxito, puede cerrar la ventana del navegador',
					{
						root: true,
					},
				)
				commit('general/setLoadingStatus', false, { root: true })
			})
			.catch((error) => {
				commit(
					'general/setMessage',
					'Ocurrió un error al guardar las respuestas',
					{
						root: true,
					},
				)
				commit('general/setLoadingStatus', false, { root: true })
				console.log(error)
			})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
