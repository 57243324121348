<template>
	<div v-if="!manageForm && !quickView" style="display: grid; width: 100%">
		<div
			style="display: flex; justify-content: space-between; align-items: center"
			class="satisfaction-form-general-row"
		>
			<h1>Encuestas</h1>
			<button
				class="satisfaction-form-create-btn-primary text-bold"
				@click=";(manageForm = true), (mode = 'create')"
			>
				<span>
					<font-awesome-icon
						style="color: white; margin-right: 2px"
						:icon="['fas', 'plus']"
					/>
				</span>
				Crear nueva encuesta
			</button>
		</div>
		<div class="satisfaction-form-general-row">
			<div class="satisfaction-form-btn-group-container">
				<button
					:class="`satisfaction-form-btn-group ${
						mode != 'metrics' ? 'active' : ''
					}`"
					@click="mode = 'list'"
				>
					LISTADO
				</button>
				<button
					:class="`satisfaction-form-btn-group ${
						mode == 'metrics' ? 'active' : ''
					}`"
					@click="mode = 'metrics'"
				>
					MÉTRICAS
				</button>
			</div>
		</div>
		<statistics v-if="mode == 'metrics'" />
		<div v-else>
			<div
				style="
					display: flex;
					justify-content: space-between;
					width: 100%;
					align-items: center;
					padding-bottom: 15px;
				"
			>
				<h3 class="satisfaction-form-header-1">
					Total encuestas:
					<span class="satisfaction-form-primary-color-detail">{{
						forms.length
					}}</span>
				</h3>
				<div class="satisfaction-form-input-wrapper">
					<input
						class="satisfaction-form-search-box"
						placeholder="Buscar por usuario, nombre, empresa o proyecto"
						@keyup="filterForms($event.target.value)"
					/>
					<font-awesome-icon
						class="satisfaction-form-input-icon"
						:icon="['fas', 'search']"
					/>
				</div>
			</div>
			<div style="overflow-x: auto; border-radius: 20px">
				<table class="satisfaction-form-data-table">
					<tr class="header">
						<th>Estado</th>
						<th>Nombre de la encuesta</th>
						<th>Evento</th>
						<th>Empresa / grupo</th>
						<th>Proyecto(s)</th>
						<th>Creador</th>
						<th>Fecha de creación</th>
						<th>Usuario que modificó</th>
						<th>Fecha de última modificación</th>
						<th>Opciones</th>
					</tr>
					<tr v-for="form in forms" :key="form.id">
						<td>
							<label class="switch">
								<input
									v-model="form.active"
									type="checkbox"
									@change="updateFormStatus(form.id)"
								/>
								<span class="slider round"></span>
							</label>
						</td>
						<td>{{ form.name }}</td>
						<td>{{ form.event.name }}</td>
						<td>{{ form.enterprise.name }}</td>
						<td :id="`projects-cell-${form.id}`">
							<p :id="`projects-cell-text-${form.id}`" class="project-text">
								{{ getProjectsFormatted(form.projects) }}
							</p>
							<p
								v-if="getProjectsFormatted(form.projects, true) > 20"
								:id="`link-show-more-${form.id}`"
								class="link-style"
								@click="seeMore(form.id)"
							>
								Ver más
							</p>
							<p
								v-if="getProjectsFormatted(form.projects, true) > 20"
								:id="`link-show-less-${form.id}`"
								class="link-style link-show-less"
								@click="seeLess(form.id)"
							>
								Ver menos
							</p>
						</td>
						<td>{{ form.user }}</td>
						<td>{{ getFormattedDate(new Date(form.created_at)) }}</td>
						<td>{{ form.last_user ? form.last_user : '-' }}</td>
						<td>{{ getFormattedDate(new Date(form.updated_at)) }}</td>
						<td class="form-menu-container">
							<font-awesome-icon
								:id="`show-${form.id}`"
								:icon="['fas', 'ellipsis-h']"
								style="cursor: pointer"
								@click="setMenu(form.id)"
							/>
							<div
								:id="`form-menu-${form.id}`"
								class="satisfaction-form-main-nav"
							>
								<ul>
									<li @click="selectForm(form.id, 'edit')">
										<font-awesome-icon
											class="primary-color-icon"
											:icon="['fas', 'edit']"
										/>
										Editar encuesta
									</li>
									<li @click="selectForm(form.id, 'preview')">
										<font-awesome-icon
											class="primary-color-icon"
											:icon="['fas', 'eye']"
										/>
										Vista rápida
									</li>
									<li @click=";(showNotification = true), selectForm(form.id)">
										<font-awesome-icon
											class="primary-color-icon"
											:icon="['fal', 'trash-alt']"
										/>
										Eliminar encuesta
									</li>
								</ul>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>

		<notification
			v-if="showNotification || getShowMessageStatus"
			header="Advertencia"
			text="¿Está seguro que desea eliminar la encuesta?"
			type="validation"
			notification-style="warning"
			@user-response="checkUserResponse"
			@close-alert="close"
		/>
	</div>
	<manage-form
		v-else-if="manageForm"
		:mode="mode"
		@back-to-form="formVisibility"
	/>
	<satisfaction-form
		v-else-if="quickView"
		@back-to-form="quickViewVisibility"
	/>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ManageForm from '@/components/ManageForm.vue'
import Notification from '@/components/Notification.vue'
import SatisfactionForm from '@/components/SatisfactionForm.vue'
import Statistics from '@/views/Statistics.vue'

export default {
	name: 'FormsPanel',
	components: {
		ManageForm,
		Notification,
		SatisfactionForm,
		Statistics,
	},
	data() {
		return {
			manageForm: false,
			mode: null,
			forms: [],
			showNotification: false,
			quickView: false,
		}
	},
	computed: {
		...mapGetters('forms', ['getFetchedForms']),
		...mapGetters('general', ['getShowMessageStatus']),
	},
	watch: {
		getFetchedForms: {
			handler: function () {
				this.forms = this.getFetchedForms
			},
			immediate: true,
		},
	},
	async mounted() {
		await this.fetchForms()
		this.forms = this.getFetchedForms
	},
	methods: {
		...mapActions('forms', ['fetchForms', 'deleteForm', 'updateFormStatus']),
		...mapMutations('forms', ['setSelectedForm']),
		...mapMutations('general', ['setshowMessageStatus']),
		filterForms(data) {
			if (!data) {
				this.forms = this.getFetchedForms
				return
			}

			const filter = data.toLowerCase()
			this.forms = this.getFetchedForms.filter((form) => {
				const projectsName = this.getProjectsName(form.projects)
				const lastUser = form.last_user ? form.last_user : ''

				return (
					form.name.toLowerCase().search(filter) > -1 ||
					form.user.toLowerCase().search(filter) > -1 ||
					lastUser.toLowerCase().search(filter) > -1 ||
					projectsName.toLowerCase().search(filter) > -1 ||
					form.enterprise.name.toLowerCase().search(filter) > -1
				)
			})
		},
		setMenu(id) {
			let menu = document.getElementById(`form-menu-${id}`)
			if (menu.className.search('active') != -1) {
				menu.className = 'satisfaction-form-main-nav'
			} else {
				menu.className += ' active'
			}
		},
		formVisibility() {
			this.manageForm = false
		},
		checkUserResponse(response) {
			if (response) this.deleteForm()
			else this.showNotification = false
		},
		selectForm(id, action = null) {
			if (action == 'preview') this.quickView = true
			else if (action == 'edit') {
				this.manageForm = true
				this.mode = 'edit'
			}
			this.setSelectedForm(id)
		},
		quickViewVisibility() {
			this.quickView = false
		},
		close() {
			this.setshowMessageStatus(false)
			this.showNotification = false
		},
		getProjectsFormatted(projects, length = false) {
			let allProjects = ''
			projects.map((project) => {
				allProjects += `${project.name} `
			})
			return length ? allProjects.length : allProjects
		},
		getFormattedDate(date) {
			var month = ('0' + (date.getMonth() + 1)).slice(-2)
			var day = ('0' + date.getDate()).slice(-2)
			var year = date.getFullYear()
			return day + '-' + month + '-' + year
		},
		seeMore(form_id) {
			document.getElementById(
				`projects-cell-text-${form_id}`,
			).style.whiteSpace = 'break-spaces'
			document.getElementById(`link-show-less-${form_id}`).style.display =
				'block'
			document.getElementById(`link-show-more-${form_id}`).style.display =
				'none'
		},
		seeLess(form_id) {
			document.getElementById(
				`projects-cell-text-${form_id}`,
			).style.whiteSpace = 'nowrap'
			document.getElementById(`link-show-less-${form_id}`).style.display =
				'none'
			document.getElementById(`link-show-more-${form_id}`).style.display =
				'block'
		},
	},
}
</script>
<style scoped>
.primary-color-icon {
	color: #00718c;
}
.link-style {
	text-decoration: underline;
	color: #00718c;
}
.link-show-less {
	display: none;
}
.project-text {
	margin: 0px;
	white-space: nowrap;
}
</style>
