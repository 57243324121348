<template>
	<div class="main-container" style="text-align: center">
		<div style="width: 100%">
			<div
				style="
					display: flex;
					justify-content: space-between;
					align-items: center;
				"
				class="satisfaction-form-general-row"
			>
				<div class="title">
					<font-awesome-icon
						style="margin: 5px"
						:icon="['fas', 'arrow-circle-left']"
						@click="$emit('show-main-stats')"
					/>
					<h1>{{ form.name }}</h1>
				</div>
				<div class="container">
					<div class="multiple-container">
						<MultipleSelect
							v-model="selectedProjects"
							placeholder="Proyecto"
							multiple
							:data="projects"
							@change="fetchData()"
						/>
					</div>
					<div class="multiple-container">
						<MultipleSelect
							v-model="selectedQuestions"
							placeholder="Preguntas"
							multiple
							:data="graphableQuestions"
							format-select-all="Seleccionar todo"
							@change="fetchData()"
						/>
					</div>
				</div>
			</div>
			<div class="satisfaction-form-general-row">
				<div class="header-container">
					<p class="satisfaction-form-header-1">Resumen de datos totales</p>
					<div class="satisfaction-form-btn-group-container">
						<button
							:class="`satisfaction-form-btn-stats-switch ${
								useEmoji ? 'active' : ''
							}`"
							@click="useEmoji = true"
						>
							<font-awesome-icon
								:icon="['fal', 'smile']"
								style="cursor: pointer"
							/>
						</button>
						<button
							:class="`satisfaction-form-btn-stats-switch ${
								useEmoji ? '' : 'active'
							}`"
							@click="useEmoji = false"
						>
							123
						</button>
					</div>
				</div>
				<div style="overflow-x: auto">
					<table class="satisfaction-form-stats-table">
						<tr>
							<th class="lighted"></th>
							<th v-for="(project, n) in getFormStats.projects_data" :key="n">
								{{ project.project_name }}
							</th>
						</tr>
						<tr
							v-for="question in filteredFormStats"
							:key="question.question_info.id"
						>
							<td class="lighted">{{ question.question_info.name }}</td>
							<td
								v-for="(project, n) in question.question_average_by_project"
								:key="n"
								:style="`color: ${averageColor(project.average)}`"
							>
								<font-awesome-icon
									v-if="useEmoji && project.average"
									:icon="['fal', `${averageEmoji(project.average)}`]"
									:style="`color: ${averageColor(project.average)}`"
								/>
								<p v-else>
									{{ project.average ? project.average : 'Sin datos' }}
								</p>
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="satisfaction-form-general-row">
				<p class="satisfaction-form-header-1">Resultados totales</p>
				<div style="overflow-x: auto">
					<table class="satisfaction-form-stats-table">
						<tr>
							<td class="lighted">Nota promedio</td>
							<td
								v-for="(project, n) in getFormStats.projects_data"
								:key="n"
								:style="`color: ${averageColor(project.average)}`"
							>
								<font-awesome-icon
									v-if="useEmoji && project.average"
									:icon="['fal', `${averageEmoji(project.average)}`]"
									:style="`color: ${averageColor(project.average)}`"
								/>
								<p v-else>
									{{ project.average ? project.average : 'Sin datos' }}
								</p>
							</td>
						</tr>
						<tr>
							<td class="lighted">Fecha encuestas respondidas</td>
							<td v-for="(project, n) in getFormStats.projects_data" :key="n">
								{{
									project.first_date
										? `${getFormattedDate(
												new Date(project.first_date),
										  )} \n hasta \n ${getFormattedDate(
												new Date(project.last_date),
										  )}`
										: 'Sin datos'
								}}
							</td>
						</tr>
						<tr>
							<td class="lighted">Encuestas contestadas</td>
							<td v-for="(project, n) in getFormStats.projects_data" :key="n">
								{{
									project.answered_forms ? project.answered_forms : 'Sin datos'
								}}
							</td>
						</tr>
						<tr>
							<td class="lighted">Clientes únicos</td>
							<td v-for="(project, n) in getFormStats.projects_data" :key="n">
								{{
									project.unique_clients ? project.unique_clients : 'Sin datos'
								}}
							</td>
						</tr>
						<tr>
							<td class="lighted">Tasa de respuesta de encuesta</td>
							<td v-for="(project, n) in getFormStats.projects_data" :key="n">
								{{
									project.response_rate ? project.response_rate : 'Sin datos'
								}}
							</td>
						</tr>
					</table>
				</div>
			</div>
			<p class="satisfaction-form-header-1">Resultados por pregunta</p>
			<div class="satisfaction-form-graphics-container">
				<div
					v-for="question in getFormStats.questions_data"
					:key="question.question_info.id"
				>
					<div class="satisfaction-form-main-stats-card">
						<div
							style="
								display: flex;
								justify-content: space-between;
								align-items: center;
							"
							class="satisfaction-form-general-row"
						>
							<div>
								<p class="satisfaction-form-header-2">
									{{ question.question_info.name }}
								</p>
								<p
									v-if="question.question_info.question_type == 'scale'"
									class="satisfaction-form-sub-header"
								>
									Promedio: {{ question.question_average }}
								</p>
							</div>
							<!-- <div class="container">
								<div class="multiple-container">
									<MultipleSelect
										v-model="selectedProjects"
										placeholder="Respuesta"
										multiple
										:data="answers(question.question_info, question.labels)"
									/>
								</div>
							</div> -->
						</div>
						<bar-chart
							:key="graphicKey"
							:labels="question.labels"
							:data="question.data"
						/>
					</div>
				</div>
			</div>
			<p class="satisfaction-form-header-1">Respuestas escritas</p>
			<div class="satisfaction-form-graphics-container">
				<div
					v-for="question in getFormStats.text_questions"
					:key="`question${question.id}`"
				>
					<div class="satisfaction-form-main-stats-card">
						<div
							style="
								display: flex;
								justify-content: space-between;
								align-items: center;
							"
							class="satisfaction-form-general-row"
						>
							<div>
								<p class="satisfaction-form-header-2">
									{{ question.name }}
								</p>
							</div>
						</div>
						<table
							style="width: 100%; text-align: left !important"
							class="satisfaction-form-stats-table"
						>
							<tr>
								<th class="lighted">Respuesta</th>
								<th class="lighted">Fecha</th>
							</tr>
							<tr
								v-for="answer in question.answers"
								:key="`answer${answer.id}`"
							>
								<td>
									{{ answer.value }}
								</td>
								<td>
									{{ getFormattedDate(new Date(answer.created_at)) }}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BarChart from '@/components/BarChart.vue'

export default {
	name: 'IndividualStats',
	components: {
		BarChart,
	},
	data() {
		BarChart
		return {
			form: {
				id: null,
				name: null,
				description: null,
				projects: [],
				questions: [],
				enterprise: null,
			},
			projects: [],
			selectedProjects: [],
			selectedQuestions: [],
			graphableQuestions: [],
			graphicKey: 0,
			useEmoji: false,
		}
	},
	computed: {
		...mapGetters('forms', ['getFormById', 'getFormStats']),
		filteredFormStats() {
			return this.getFormStats.questions_data.filter(
				(question) => question.question_info.question_type == 'scale',
			)
		},
	},
	async mounted() {
		this.form = await this.getFormById()
		this.graphableQuestions = this.form.questions
			.filter((question) => question.question_type != 'text')
			.map((question) => {
				return {
					text: question.name,
					value: question.id,
				}
			})
		this.projects = this.form.projects.map((project) => {
			return {
				text: project.name,
				value: project.id,
			}
		})
		this.scaleQuestions = this.form.questions.filter(
			(question) => question.question_type == 'scale',
		)
	},
	methods: {
		...mapActions('forms', ['fetchFormStats', 'fetchQuestionStats']),
		fetchData() {
			this.graphicKey += 1
			const data = {
				projects: this.selectedProjects,
				questions: this.selectedQuestions,
			}
			this.fetchFormStats(data)
		},
		averageColor(value) {
			let color = ''
			switch (value) {
				case value >= 0.1 && value <= 3.9:
					color = 'red'
					break
				case value >= 5.5 && value <= 7:
					color = 'green'
					break
				default:
					color = 'black'
					break
			}
			return color
		},
		answers(questionData, data) {
			if (questionData.question_type == 'multiple') {
				const options = this.form.questions.find(
					(question) => question.id == questionData.id,
				)['options']
				return options.map((option) => {
					return {
						text: option.name,
						value: option.id,
					}
				})
			} else {
				return data.map((element) => {
					return {
						text: element,
						value: element,
					}
				})
			}
		},
		getFormattedDate(date) {
			var month = ('0' + (date.getMonth() + 1)).slice(-2)
			var day = ('0' + date.getDate()).slice(-2)
			var year = date.getFullYear()
			return day + '-' + month + '-' + year
		},
		averageEmoji(value) {
			let emoji = ''
			switch (value) {
				case value >= 0 && value <= 3.9:
					emoji = 'frown'
					break
				case value >= 5.5 && value <= 7:
					emoji = 'smile'
					break
				default:
					emoji = 'meh'
					break
			}
			return emoji
		},
	},
}
</script>
<style lang="scss" scoped>
.multiple-container {
	width: 250px;
	margin-right: 5px;
}
.container {
	display: flex;
	justify-content: flex-end;
}
.satisfaction-form-header-1 {
	margin-bottom: 20px;
	text-align: start;
	font-style: bold;
}
h1 {
	text-align: start;
}
.header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.title {
	display: flex;
	align-items: center;
}
.main-container {
	z-index: 9999;
	position: absolute;
	background-color: #e5e5e5;
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: #fff;
}
</style>
