<template>
	<div class="satisfaction-form-notification-container">
		<div v-if="type != 'full'" class="satisfaction-form-notification">
			<div v-if="loading && getShowMessageStatus" class="loader"></div>
			<font-awesome-icon
				v-else
				class="satisfaction-form-notification-icon"
				:icon="icon"
			/>
			<div class="header">
				<h1 v-if="loading">Cargando</h1>
				<h1 v-else-if="!loading && getShowMessageStatus">Información</h1>
				<h1 v-else>{{ header }}</h1>
			</div>
			<div class="content">
				<p v-if="getShowMessageStatus">{{ generalMessage }}</p>
				<p v-else>{{ text }}</p>
			</div>
			<div v-if="!loading && getShowMessageStatus" class="footer">
				<button
					class="satisfaction-form-btn-primary"
					@click="$emit('close-alert')"
				>
					Aceptar
				</button>
			</div>
			<div v-else class="footer">
				<button
					v-if="type != 'notification'"
					class="satisfaction-form-btn-secondary"
					@click="$emit('user-response', false)"
				>
					Cancelar
				</button>
				<button
					class="satisfaction-form-btn-primary"
					@click="$emit('user-response', true)"
				>
					Aceptar
				</button>
			</div>
		</div>
		<div v-else class="satisfaction-form-notification-expanded">
			<div class="satisfaction-form-notification-card">
				<div v-if="loading && getShowMessageStatus" class="loader"></div>
				<font-awesome-icon
					v-else
					class="satisfaction-form-notification-icon"
					:icon="icon"
				/>
				<div class="header">
					<h1 v-if="loading">Cargando</h1>
					<h1 v-else-if="!loading && getShowMessageStatus">Información</h1>
					<h1 v-else>{{ header }}</h1>
				</div>
				<div class="content">
					<p v-if="getShowMessageStatus">{{ generalMessage }}</p>
					<p v-else>{{ text }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'Notification',
	props: {
		icon: { type: Array, default: () => ['fal', 'exclamation-circle'] },
		header: { type: String, default: '' },
		text: { type: String, default: '' },
		type: { type: String, default: 'notification' },
		notificationStyle: { type: String, default: 'info' },
	},
	data() {
		return {}
	},
	computed: {
		...mapState('general', ['generalMessage', 'loading']),
		...mapGetters('general', ['getShowMessageStatus']),
	},
}
</script>

<style lang="scss" scoped>
button {
	margin-left: 10px;
	margin-right: 10px;
}
</style>
