import Vue from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vueCustomElement from 'vue-custom-element'
import Vuelidate from 'vuelidate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faSearch,
	faPlus,
	faEllipsisH,
	faEdit,
	faEye,
	faArrowCircleLeft,
	faStar,
	faTimes,
	faCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
	faCopy,
	faDownload,
	faStar as falStar,
	faTrashAlt,
	faCircle as falCircle,
	faExclamationCircle,
	faInfoCircle,
	faPaperPlane,
	faCommentAltLines,
	faSmile,
	faMeh,
	faFrown,
} from '@fortawesome/pro-light-svg-icons'
import './plugins/select'

Vue.config.productionTip = false
const instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
})

library.add(
	faSearch,
	faPlus,
	faEllipsisH,
	faEdit,
	faTrashAlt,
	faEye,
	faArrowCircleLeft,
	faDownload,
	faCopy,
	faStar,
	falStar,
	faCircle,
	faTimes,
	faExclamationCircle,
	faInfoCircle,
	faPaperPlane,
	faCommentAltLines,
	falCircle,
	faSmile,
	faMeh,
	faFrown,
)

Vue.use(VueAxios, instance)
Vue.use(vueCustomElement)
Vue.use(Vuelidate)
Vue.component('font-awesome-icon', FontAwesomeIcon)
App.store = store

Vue.customElement('mobysuite-satisfaction-form', App)
