<template>
	<div v-if="!showIndividualView">
		<div class="satisfaction-form-general-row" style="text-align: end">
			<div class="container">
				<div class="multiple-container">
					<MultipleSelect
						v-model="selectedProjects"
						placeholder="Proyecto"
						multiple
						:data="projects"
						@change="fetchData"
					/>
				</div>
				<div class="multiple-container">
					<MultipleSelect
						v-model="selectedMilestones"
						placeholder="Evento"
						multiple
						:data="milestones"
						@change="fetchData"
					/>
				</div>
				<div class="multiple-container">
					<MultipleSelect
						v-model="selectedForms"
						placeholder="Encuesta"
						multiple
						:data="forms"
						@change="fetchData"
					/>
				</div>
			</div>
		</div>
		<div style="display: flex">
			<div style="width: 20%">
				<div class="satisfaction-form-metric-card">
					<div class="header">
						<div class="icon-container">
							<font-awesome-icon
								style="color: white; margin-right: 2px; font-size: 20px"
								:icon="['fal', 'paper-plane']"
							/>
						</div>
						<h3>{{ getGeneralStats.total_submitted_forms }}</h3>
					</div>
					<p>Total encuestas enviadas</p>
				</div>
				<div class="satisfaction-form-metric-card">
					<div class="header">
						<div class="icon-container">
							<font-awesome-icon
								style="color: white; margin-right: 2px; font-size: 20px"
								:icon="['fal', 'comment-alt-lines']"
							/>
						</div>
						<h3>{{ getGeneralStats.total_answered_forms }}</h3>
					</div>
					<p>Total encuestas respondidas</p>
				</div>
			</div>
			<div style="width: 80%">
				<div class="satisfaction-form-main-stats-card">
					<bar-chart
						:key="statsKey"
						:labels="getGeneralStats.projects.labels"
						:data="getGeneralStats.projects.data"
					/>
				</div>
			</div>
		</div>
		<div>
			<div class="satisfaction-form-general-row" style="text-align: end">
				<div
					style="
						display: flex;
						justify-content: end;
						width: 100%;
						align-items: center;
						padding-bottom: 15px;
						padding-top: 15px;
					"
				>
					<div class="satisfaction-form-input-wrapper">
						<input
							class="satisfaction-form-search-box"
							placeholder="Buscar por nombre o proyecto"
							@keyup="filterForms($event.target.value)"
						/>
						<font-awesome-icon
							class="satisfaction-form-input-icon"
							:icon="['fas', 'search']"
						/>
					</div>
					<div class="multiple-container">
						<MultipleSelect
							v-model="selectedStatus"
							placeholder="Estado"
							multiple
							:data="status"
							@change="fetchData"
						/>
					</div>
				</div>
			</div>
			<div style="overflow-x: auto; border-radius: 20px">
				<table class="satisfaction-form-data-table">
					<tr class="header">
						<th>Estado</th>
						<th>Nombre de la encuesta</th>
						<th>Evento</th>
						<th>Proyecto(s)</th>
						<th>Respuestas</th>
						<th>Opciones</th>
					</tr>
					<tr v-for="form in fetchedForms" :key="form.id">
						<td>{{ form.active ? 'Activo' : 'Inactivo' }}</td>
						<td>{{ form.name }}</td>
						<td>{{ form.event.name }}</td>
						<td :id="`projects-cell-${form.id}`">
							<p :id="`projects-cell-text-${form.id}`" class="project-text">
								{{ getProjectsFormatted(form.projects) }}
							</p>
							<p
								v-if="getProjectsFormatted(form.projects, true) > 20"
								:id="`link-show-more-${form.id}`"
								class="link-style"
								@click="seeMore(form.id)"
							>
								Ver más
							</p>
							<p
								v-if="getProjectsFormatted(form.projects, true) > 20"
								:id="`link-show-less-${form.id}`"
								class="link-style link-show-less"
								@click="seeLess(form.id)"
							>
								Ver menos
							</p>
						</td>
						<td>{{ form.answers }}</td>
						<td class="form-menu-container">
							<font-awesome-icon
								:id="`show-${form.id}`"
								:icon="['fas', 'ellipsis-h']"
								style="cursor: pointer"
								@click="setMenu(form.id)"
							/>
							<div
								:id="`form-menu-${form.id}`"
								class="satisfaction-form-main-nav"
							>
								<ul>
									<li
										@click="
											;(showIndividualView = true), setSelectedForm(form.id)
										"
									>
										<font-awesome-icon
											class="primary-color-icon"
											:icon="['fas', 'eye']"
										/>
										Ver resultados
									</li>
								</ul>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<individual-stats v-else @show-main-stats="showIndividualView = false" />
</template>

<script>
import BarChart from '@/components/BarChart.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import IndividualStats from '@/components/IndividualStats.vue'

export default {
	name: 'Statistics',
	components: {
		BarChart,
		IndividualStats,
	},
	data() {
		return {
			status: [
				{
					value: true,
					text: 'Activo',
				},
				{
					value: false,
					text: 'Inactivo',
				},
			],
			milestones: [],
			projects: [],
			forms: [],
			selectedProjects: [],
			selectedMilestones: [],
			selectedForms: [],
			statsKey: 0,
			fetchedForms: [],
			showIndividualView: false,
			selectedStatus: [],
		}
	},
	computed: {
		...mapGetters('general', [
			'getFetchedEnterprises',
			'getFetchedProjects',
			'getFetchedEvents',
		]),
		...mapGetters('forms', ['getGeneralStats']),
	},
	watch: {
		getGeneralStats: {
			handler: function () {
				this.statsKey += 1
				this.fetchedForms = this.getGeneralStats.forms
				this.forms = this.getGeneralStats.forms.map((form) => {
					return {
						text: form.name,
						value: form.id,
					}
				})
			},
			immediate: true,
		},
	},
	mounted() {
		this.projects = this.getFetchedProjects.map((project) => {
			return {
				text: project.name,
				value: project.id,
			}
		})
		this.milestones = this.getFetchedEvents.map((milestone) => {
			return {
				text: milestone.name,
				value: milestone.id,
			}
		})
	},
	methods: {
		...mapActions('forms', ['fetchStats']),
		...mapMutations('forms', ['setSelectedForm']),
		fetchData() {
			const data = {
				projects: this.selectedProjects,
				milestones: this.selectedMilestones,
				forms: this.selectedForms,
				status: this.selectedStatus,
			}
			this.fetchStats(data)
		},
		setMenu(id) {
			let menu = document.getElementById(`form-menu-${id}`)
			if (menu.className.search('active') != -1) {
				menu.className = 'satisfaction-form-main-nav'
			} else {
				menu.className += ' active'
			}
		},
		getProjectsName(projects) {
			let allProjects = ''
			projects.map((project) => {
				allProjects += `${project.name} `
			})
			return allProjects
		},
		filterForms(data) {
			if (!data) {
				this.fetchedForms = this.getGeneralStats.forms
				return
			}

			const filter = data.toLowerCase()
			this.fetchedForms = this.getGeneralStats.forms.filter((form) => {
				const projectsName = this.getProjectsName(form.projects)
				return (
					form.name.toLowerCase().search(filter) > -1 ||
					projectsName.toLowerCase().search(filter) > -1
				)
			})
		},
		getProjectsFormatted(projects, length = false) {
			let allProjects = ''
			projects.map((project) => {
				allProjects += `${project.name} `
			})
			return length ? allProjects.length : allProjects
		},
		seeMore(form_id) {
			document.getElementById(
				`projects-cell-text-${form_id}`,
			).style.whiteSpace = 'break-spaces'
			document.getElementById(`link-show-less-${form_id}`).style.display =
				'block'
			document.getElementById(`link-show-more-${form_id}`).style.display =
				'none'
		},
		seeLess(form_id) {
			document.getElementById(
				`projects-cell-text-${form_id}`,
			).style.whiteSpace = 'nowrap'
			document.getElementById(`link-show-less-${form_id}`).style.display =
				'none'
			document.getElementById(`link-show-more-${form_id}`).style.display =
				'block'
		},
	},
}
</script>
<style lang="scss" scoped>
.satisfaction-form-search-box {
	margin-right: 10px;
}
.multiple-container {
	width: 250px;
	margin-right: 5px;
}
.container {
	display: flex;
	justify-content: flex-end;
}
.link-style {
	text-decoration: underline;
	color: #00718c;
}
.link-show-less {
	display: none;
}
.project-text {
	margin: 0px;
	white-space: nowrap;
}
</style>
