import Vue from 'vue'

const state = {
	fetchedForms: [],
	sendingForm: false,
	formCreated: false,
	selectedFormId: null,
	propertyNames: {
		id: 'id',
	},
	generalStats: {
		total_submitted_forms: null,
		total_answered_forms: null,
		projects: {
			labels: [],
			data: [],
		},
	},
	formStats: {
		questions_data: [],
		projects_data: [],
	},
}

const mutations = {
	setFetchedForms: (state, data) => (state.fetchedForms = data),
	setSelectedForm: (state, data) => (state.selectedFormId = data),
	setStatsData: (state, data) => (state.generalStats = data),
	setFormStats: (state, data) => (state.formStats = data),
}

const getters = {
	getFetchedForms: (state) => state.fetchedForms,
	getFormById: (state) => (id) => {
		const formId = id ? id : state.selectedFormId
		return state.fetchedForms.find(
			(form) => form[state.propertyNames.id] == formId,
		)
	},
	getGeneralStats: (state) => state.generalStats,
	getFormStats: (state) => state.formStats,
}
const actions = {
	async fetchForms({ commit, rootState }) {
		await Vue.axios
			.get('forms', {
				params: {
					customer_id: rootState.general.customer.id,
					customer: rootState.general.customer.name,
				},
			})
			.then((response) => {
				commit('setFetchedForms', response.data)
			})
			.catch((error) => {
				console.log(error)
			})
	},
	async sendForm({ rootState, commit, dispatch }, data) {
		commit('general/setshowMessageStatus', true, { root: true })
		commit('general/setMessage', 'Registrando formulario', { root: true })
		commit('general/setLoadingStatus', true, { root: true })
		await Vue.axios
			.post('forms', {
				customer_id: rootState.general.customer.id,
				customer: rootState.general.customer.name,
				user: {
					id: rootState.general.user.id,
					name: rootState.general.user.name,
					lastname: rootState.general.user.lastname,
				},
				...data,
			})
			.then(() => {
				commit('general/setMessage', 'Formulario registrado con éxito', {
					root: true,
				})
				commit('general/setLoadingStatus', false, { root: true })
				dispatch('fetchForms')
			})
			.catch((error) => {
				commit(
					'general/setMessage',
					'Ocurrió un error al registrar el formulario',
					{ root: true },
				)
				commit('general/setLoadingStatus', false, { root: true })
				commit('setshowMessageStatus', false)
				console.log(error)
			})
	},
	async updateForm({ rootState, commit, dispatch }, data) {
		commit('general/setshowMessageStatus', true, { root: true })
		commit('general/setMessage', 'Registrando formulario', { root: true })
		commit('general/setLoadingStatus', false, { root: true })
		await Vue.axios
			.patch(`forms/${data.id}`, {
				customer_id: rootState.general.customer.id,
				customer: rootState.general.customer.name,
				user: {
					id: rootState.general.user.id,
					name: rootState.general.user.name,
					lastname: rootState.general.user.lastname,
				},
				...data,
			})
			.then(() => {
				commit('general/setMessage', 'Formulario actualizado con éxito', {
					root: true,
				})
				commit('general/setLoadingStatus', false, { root: true })
				dispatch('fetchForms')
			})
			.catch((error) => {
				commit(
					'general/setMessage',
					'Ocurrió un error al actualizar el formulario',
					{
						root: true,
					},
				)
				commit('general/setLoadingStatus', false, { root: true })
				console.log(error)
			})
	},
	async deleteForm({ commit, dispatch, state }) {
		commit('general/setshowMessageStatus', true, { root: true })
		commit('general/setMessage', 'Procesando la solicitud', { root: true })
		commit('general/setLoadingStatus', true, { root: true })
		await Vue.axios
			.delete(`forms/${state.selectedFormId}`)
			.then(() => {
				commit('general/setMessage', 'Formulario eliminado', {
					root: true,
				})
				commit('general/setLoadingStatus', false, { root: true })
				dispatch('fetchForms')
			})
			.catch((error) => {
				commit('general/setMessage', 'No se pudo eliminar este formulario', {
					root: true,
				})
				commit('general/setLoadingStatus', false, { root: true })
				console.log(error)
			})
	},
	async fetchStats({ commit, rootState }, data) {
		await Vue.axios
			.get('reports/forms', {
				params: {
					customer_id: rootState.general.customer.id,
					customer: rootState.general.customer.name,
					...data,
				},
			})
			.then((response) => {
				commit('setStatsData', response.data)
			})
			.catch((error) => {
				console.log(error)
			})
	},
	async fetchFormStats({ commit, rootState, state }, data) {
		await Vue.axios
			.get(`reports/forms/${state.selectedFormId}`, {
				params: {
					customer_id: rootState.general.customer.id,
					customer: rootState.general.customer.name,
					...data,
				},
			})
			.then((response) => {
				commit('setFormStats', response.data)
			})
			.catch((error) => {
				console.log(error)
			})
	},
	async fetchQuestionStats({ commit, rootState }, data) {
		await Vue.axios
			.get('reports/questions', {
				params: {
					customer_id: rootState.general.customer.id,
					customer: rootState.general.customer.name,
					...data,
				},
			})
			.then((response) => {
				commit('setStatsData', response.data)
			})
			.catch((error) => {
				console.log(error)
			})
	},
	async updateFormStatus({ rootState, dispatch }, id) {
		await Vue.axios
			.post('forms/update_form_status', {
				customer_id: rootState.general.customer.id,
				form_id: id,
			})
			.then(() => {
				console.log('Formulario actualizado')
				dispatch('fetchForms')
			})
			.catch((error) => {
				console.log(error)
			})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
