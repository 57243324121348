import Vue from 'vue'
import axios from 'axios'

const themeAPI = axios.create({
	baseURL: process.env.VUE_APP_QUOTE_API_URL,
})

const state = {
	mode: null,
	customer: {
		id: null,
		name: null,
	},
	user: {
		id: null,
		name: null,
		lastname: null,
	},
	propertyNames: {
		enterprise: 'empresa',
		id: 'id',
		name: 'nombre',
		projectName: 'nombreProyecto',
	},
	fetchedProjects: [],
	fetchedEnterprises: [],
	generalMessage: null,
	showMessage: false,
	loading: false,
	fetchedEvents: [],
	customerLogo: null,
}

const mutations = {
	setMode: (state, data) => (state.mode = data),
	setCustomerData: (state, data) => (state.customer = data),
	setUserData: (state, data) => (state.user = data),
	setFetchedProjects: (state, data) => (state.fetchedProjects = data),
	setFetchedEnterprises: (state, data) => (state.fetchedEnterprises = data),
	setshowMessageStatus: (state, data) => {
		state.showMessage = data
	},
	setMessage: (state, data) => {
		state.generalMessage = data
	},
	setLoadingStatus: (state, data) => (state.loading = data),
	setFetchedEvents: (state, data) => (state.fetchedEvents = data),
	setCustomerLogo: (state, data) => (state.customerLogo = data),
}

const getters = {
	getFetchedProjects: (state) =>
		state.fetchedProjects.map((project) => {
			return {
				id: project[state.propertyNames.id],
				name: project[state.propertyNames.projectName],
				enterprise: {
					id: project[state.propertyNames.enterprise][state.propertyNames.id],
				},
			}
		}),
	getFetchedEnterprises: (state) =>
		state.fetchedEnterprises.map((enterprise) => {
			return {
				id: enterprise[state.propertyNames.id],
				name: enterprise[state.propertyNames.name],
			}
		}),
	getFetchedEvents: (state) =>
		state.fetchedEvents.map((event) => {
			return { id: event.id, name: event.name }
		}),
	getShowMessageStatus: (state) => state.showMessage,
}

const actions = {
	async fetchProjects({ commit, rootState, state }) {
		await Vue.axios
			.get('projects/list_projects', {
				params: {
					customer: rootState.general.customer.name,
				},
			})
			.then((response) => {
				const enterprises = response.data.map(
					(project) => project[state.propertyNames.enterprise],
				)
				const formattedEnterprises = [
					...new Set(enterprises.map(JSON.stringify)),
				].map(JSON.parse)
				commit('setFetchedEnterprises', formattedEnterprises)
				commit('setFetchedProjects', response.data)
			})
			.catch((error) => {
				console.log(error)
			})
	},
	async fetchEvents({ commit }) {
		await Vue.axios
			.get('event_types', {})
			.then((response) => {
				commit('setFetchedEvents', response.data)
			})
			.catch((error) => {
				console.log(error)
			})
	},
	async fetchCustomerInfo({ commit, rootState }) {
		await themeAPI
			.get('/list-real-estate-info', {
				params: {
					real_estate: rootState.general.customer.name,
				},
			})
			.then((response) => {
				commit('setCustomerLogo', response.data.logo_1_url)
			})
			.catch((error) => {
				console.log(error)
			})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
