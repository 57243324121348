<template>
	<div v-if="status != 'deleted'" class="satisfaction-form-card">
		<div class="body">
			<div class="satisfaction-form-general-row">
				<div class="satisfaction-form-general-col">
					<input
						v-model="name"
						type="text"
						style="margin-right: 5px"
						class="satisfaction-form-rounded-input"
						placeholder="Pregunta"
						@keyup="updateParent('name', name)"
					/>
					<select
						id=""
						v-model="type"
						style="margin-left: 5px"
						class="satisfaction-form-general-select"
						name="type"
						@change="updateParent('type', type)"
					>
						<option value="null" hidden selected>Tipo</option>
						<option value="multiple">Opción múltiple</option>
						<option value="text">Texto</option>
						<option value="polar">Sí/No</option>
						<option value="scale">Escala</option>
					</select>
				</div>
			</div>
			<div v-if="type == 'scale'" class="satisfaction-form-general-row">
				<select
					id=""
					v-model="scale"
					style="width: 80px; margin-right: 5px"
					class="satisfaction-form-general-select"
					name="scale"
					@change="updateParent('scale', scale)"
				>
					<option :value="null" selected>Escala</option>
					<option :value="2">2</option>
					<option :value="3">3</option>
					<option :value="4">4</option>
					<option :value="5">5</option>
					<option :value="6">6</option>
					<option :value="7">7</option>
					<option :value="8">8</option>
					<option :value="9">9</option>
					<option :value="10">10</option>
				</select>
				<select
					id=""
					v-model="emoji"
					style="width: 100px"
					class="satisfaction-form-general-select"
					name="emoji"
					@change="updateParent('emoji', emoji)"
				>
					<option :value="null">Ninguno</option>
					<option value="star">Estrellas</option>
				</select>
				<div v-if="scale != null" class="scale-container">
					<div v-if="emoji == null && scale != null" class="scale-element">
						<font-awesome-icon
							class="icon scale-element"
							:icon="['fal', 'circle']"
						/>
						<br />
						0
					</div>
					<template v-for="n in scale">
						<font-awesome-icon
							v-if="emoji != null"
							:key="`emoji-${n}`"
							style="font-size: 18px"
							class="icon scale-element"
							:icon="['fal', `${emoji}`]"
						/>
						<div v-else :key="`no-emoji-${n}`" class="scale-element">
							<font-awesome-icon
								class="icon scale-element"
								:icon="['fal', 'circle']"
							/>
							<br />
							{{ n }}
						</div>
					</template>
				</div>
			</div>
			<div v-if="type == 'multiple'" class="satisfaction-form-general-row">
				<div
					v-for="(option, index) in options"
					:key="index"
					style="display: flex; align-items: end"
				>
					<input
						v-model="options[index]"
						value="option"
						type="text"
						class="satisfaction-form-option-input"
						style="display: block"
						@keyup="updateParent('options', options)"
					/>
					<font-awesome-icon
						:icon="['fas', 'times']"
						style="padding-left: 5px"
						@click="deleteOption(options[index])"
					/>
				</div>
				<button
					class="satisfaction-form-btn-rounded-small"
					style="font-size: 8px"
					@click="addOption"
				>
					<span>
						<font-awesome-icon style="color: white" :icon="['fas', 'plus']" />
					</span>
				</button>
			</div>
		</div>
		<div class="footer">
			<p>Requerida</p>
			<label class="switch">
				<input
					v-model="required"
					type="checkbox"
					@change="updateParent('required', required)"
				/>
				<span class="slider round"></span>
			</label>
			<!-- Note: type != multiple is a temporal fix, duplicate functionality was affecting original question 'options' model -->
			<font-awesome-icon
				v-if="type != 'multiple'"
				class="icon middle-icon"
				:icon="['fal', 'copy']"
				@click="$emit('duplicate-question', id)"
			/>
			<font-awesome-icon
				class="icon"
				:icon="['fal', 'trash-alt']"
				@click="$emit('delete-question', id), (status = 'deleted')"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Question',
	props: {
		id: { type: Number, default: null },
	},

	data() {
		return {
			name: '',
			options: [],
			type: 'text',
			required: true,
			scale: null,
			emoji: null,
			status: null,
		}
	},

	created() {
		this.name = this.$attrs.name
		this.options = this.$attrs.options
		this.type = this.$attrs.type
		this.required = this.$attrs.required
	},

	methods: {
		updateParent(input, value) {
			this.status = 'updated'
			this.$emit('update-question', {
				propertyName: input,
				propertyValue: value,
				id: this.id,
			})
			if (input == 'type') {
				this.options = []
				if (value == 'multiple') this.options.push('')
			}
		},
		addOption() {
			this.options.push('')
		},
		deleteOption(element) {
			const index = this.options.findIndex((option) => option == element)
			this.options.splice(index, 1)
		},
	},
}
</script>
<style>
.scale-container {
	display: flex;
}
.scale-element {
	padding: 5px;
	text-align: center;
}
.satisfaction-form-btn-rounded-small {
	background-color: #525252 !important;
	margin-top: 5px;
}
</style>
