<template>
	<div>
		<div v-if="form && !showNotification && !getShowMessageStatus">
			<div class="satisfaction-form-main-card">
				<div class="answers-header">
					<img
						class="satisfaction-form-customer-logo"
						:src="customerLogo"
						alt=""
					/>
					<p>{{ form.name }}</p>
				</div>
				<div class="satisfaction-form-form-general-data">
					<div>
						{{ form.description }}
					</div>
					<div class="details">
						<p>Proyecto(s): {{ getProjectsName(form.projects) }}</p>
						<p>Empresa: {{ form.enterprise.name }}</p>
					</div>
				</div>
			</div>
			<div class="satisfaction-form-preview-card">
				<div
					v-for="(question, index) in form.questions"
					:key="question.id"
					class="question"
				>
					<p>
						{{ (index += 1) }}.- {{ question.name }}
						<span v-if="question['required']" class="required"> Requerido</span>
					</p>
					<textarea
						v-if="question.question_type == 'text'"
						cols="30"
						rows="2"
						class="response"
						@keyup="setAnswer($event.target.value, question.id)"
					></textarea>
					<div v-if="question.question_type == 'multiple'">
						<div
							v-for="option in question.options"
							:key="option.id"
							class="satisfaction-form-question-option response"
						>
							<input
								:id="option.id"
								type="radio"
								:name="`${question}${question.id} `"
								@click="setAnswer(option.id, question.id)"
							/>
							<label :for="option.id"> {{ option.name }}</label>
						</div>
					</div>
					<div v-if="question.question_type == 'polar'">
						<div class="satisfaction-form-question-option response">
							<input
								id="1"
								type="radio"
								:name="`${question.name}${question.id} `"
								value="Sí"
								@click="setAnswer($event.target.value, question.id)"
							/>
							<label for="Sí"> Sí</label>
						</div>
						<div class="satisfaction-form-question-option response">
							<input
								id="2"
								type="radio"
								:name="`${question.name}${question.id} `"
								value="No"
								@click="setAnswer($event.target.value, question.id)"
							/>
							<label for="No"> No</label>
						</div>
					</div>
					<div
						v-if="question.question_type == 'scale'"
						class="scale-container response"
					>
						<div v-if="question['options'][0].emoji == null">
							<div
								v-if="checkScaleQuestionAnswer(question.id, 0)"
								class="scale-element"
							>
								<font-awesome-icon
									class="icon scale-element"
									style="color: grey"
									:icon="['fas', 'circle']"
									@click="setAnswer(0, question.id)"
								/>
								<br />
								0
							</div>
							<div v-else class="scale-element">
								<font-awesome-icon
									class="icon scale-element"
									:icon="['fal', 'circle']"
									@click="setAnswer(0, question.id)"
								/>
								<br />
								0
							</div>
						</div>
						<div v-for="n in question['options'][0].scale" :key="n">
							<div v-if="question['options'][0].emoji != null">
								<font-awesome-icon
									v-if="checkScaleQuestionAnswer(question.id, n, 'range')"
									:id="`icon-${n}`"
									style="font-size: 18px; color: orange"
									class="icon scale-element"
									:icon="['fas', `${question['options'][0].emoji}`]"
									@click="setAnswer(n, question.id)"
								/>
								<font-awesome-icon
									v-else
									:id="`icon-${n}`"
									style="font-size: 18px"
									class="icon scale-element"
									:icon="['fal', `${question['options'][0].emoji}`]"
									@click="setAnswer(n, question.id)"
								/>
							</div>
							<div v-else>
								<div
									v-if="checkScaleQuestionAnswer(question.id, n)"
									class="scale-element"
								>
									<font-awesome-icon
										class="icon scale-element"
										style="color: grey"
										:icon="['fas', 'circle']"
										@click="setAnswer(n, question.id)"
									/>
									<br />
									{{ n }}
								</div>
								<div v-else class="scale-element">
									<font-awesome-icon
										class="icon scale-element"
										:icon="['fal', 'circle']"
										@click="setAnswer(n, question.id)"
									/>
									<br />
									{{ n }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="satisfaction-form-centered-container" style="text-align: end">
				<p v-if="showError" style="color: red">
					Por favor, responda las preguntas obligatorias
				</p>
				<button class="satisfaction-form-btn-primary text-bold" @click="save()">
					<span>
						<font-awesome-icon
							style="color: white; margin-right: 2px"
							:icon="['fal', 'download']"
						/>
					</span>
					Guardar
				</button>
			</div>
		</div>
		<div v-else>
			<notification
				v-if="showNotification"
				header="Lo sentimos"
				text="El indentificador proporcionado no es válido"
				type="full"
				:icon="['fas', 'times']"
			/>
			<notification
				v-else
				text="Estamos procesando su información"
				type="full"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Notification from '@/components/Notification.vue'

export default {
	name: 'Answerform',
	components: {
		Notification,
	},
	data() {
		return {
			showNotification: false,
			form: {
				id: null,
				name: '',
				description: '',
				projects: [],
				enterprise: {
					name: '',
				},
				questions: [],
			},
			answers: [],
			showError: false,
		}
	},
	computed: {
		...mapGetters('general', ['getShowMessageStatus']),
		...mapGetters('answers', ['getFetchedForm']),
		...mapState('answers', ['token']),
		...mapState('general', ['loading', 'customerLogo', 'generalMessage']),
	},
	watch: {
		getFetchedForm: {
			handler: function () {
				this.form = this.getFetchedForm
			},
			immediate: true,
		},
	},
	async created() {
		if (this.token) {
			this.validateToken()
			this.fetchCustomerInfo()
		} else this.showNotification = true
	},
	methods: {
		...mapActions('answers', ['validateToken', 'saveAnswers']),
		...mapActions('general', ['fetchCustomerInfo']),
		setAnswer(answerData, question) {
			const index = this.answers.findIndex(
				(answer) => answer.question_id == question,
			)
			if (index > -1) {
				let answer = this.answers[index]
				answer['answer'] = answerData
				this.answers.splice(index, 1, answer)
			} else {
				this.answers.push({ question_id: question, answer: answerData })
			}
		},
		checkScaleQuestionAnswer(question_id, position, type = null) {
			const answerData = this.answers.find(
				(answer) => answer.question_id == question_id,
			)
			if (type == 'range') {
				return answerData ? answerData['answer'] >= position : false
			}

			return answerData ? answerData['answer'] == position : false
		},
		save() {
			this.showError = false
			if (this.validateForm()) {
				const payload = {
					form: this.form['id'],
					answers: this.answers,
				}
				this.saveAnswers(payload)
			} else this.showError = true
		},
		validateForm() {
			for (let index = 0; index < this.form.questions.length; index++) {
				if (
					this.form.questions[index]['required'] &&
					!this.answers.find(
						(answer) => answer['question_id'] == this.form.questions[index].id,
					)
				)
					return false
			}
			return true
		},
		getProjectsName(projects) {
			let allProjects = ''
			projects.map((project) => {
				allProjects += `${project.name} `
			})
			return allProjects
		},
	},
}
</script>
<style lang="scss" scoped>
.satisfaction-form-main-card {
	> .answers-header {
		background-color: #fff;
		color: #000;
		height: 40px;
		border-radius: 10px 10px 0px 0px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		border: solid #747474 !important;
		border-width: 0px 0px 1px 0px !important;
		position: relative;
		text-align: center;
		> p {
			font-size: 18px !important;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
		}
	}
}
.satisfaction-form-form-general-data {
	> .details {
		color: #525252;
	}
}
.satisfaction-form-btn-primary {
	background-color: #000 !important;
}
.required {
	color: red;
	font-size: xx-small;
}
</style>
