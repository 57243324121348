<template>
	<div id="app">
		<Main></Main>
	</div>
</template>

<script>
import Main from '@/views/Main.vue'
import { mapMutations } from 'vuex'
export default {
	name: 'App',
	components: {
		Main,
	},
	props: {
		mode: { type: String, default: 'editor' },
		customer: { type: String, default: null },
		customerId: { type: Number, default: null },
		userId: { type: String, default: null },
		userName: { type: String, default: null },
		userLastname: { type: String, default: null },
		token: { type: String, default: null },
	},
	created() {
		this.setMode(this.mode)
		this.setCustomerData({ id: this.customerId, name: this.customer })
		this.setUserData({
			id: this.userId,
			name: this.userName,
			lastname: this.userLastname,
		})
		this.setToken(this.token)
	},
	methods: {
		...mapMutations('general', ['setMode', 'setCustomerData', 'setUserData']),
		...mapMutations('answers', ['setToken']),
	},
}
</script>

<style lang="scss">
@use 'sass:meta';
@import 'assets/main';

mobysuite-satisfaction-form {
	th {
		text-align: center;
	}
	.satisfaction-form-btn-group-container {
		display: flex;
	}
	h1 {
		font-size: 26px;
	}
}
</style>
