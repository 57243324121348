import Vue from 'vue'
import Vuex from 'vuex'
import general from '@/store/modules/general'
import forms from '@/store/modules/forms'
import answers from '@/store/modules/answers'

Vue.use(Vuex)

const modules = {
	general,
	forms,
	answers,
}

export default new Vuex.Store({
	modules,
})
