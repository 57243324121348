<template>
	<div class="satisfaction-form-notification-container-preview">
		<div class="satisfaction-form-modal-container">
			<div class="satisfaction-form-main-card">
				<div class="header">
					<font-awesome-icon
						class="satisfaction-form-input-icon"
						:icon="['fas', 'arrow-circle-left']"
						@click="$emit('back-to-form')"
					/>
					<p>{{ form.name }}</p>
				</div>
				<div class="satisfaction-form-form-general-data">
					<div>
						{{ form.description }}
					</div>
					<div class="details">
						<p><b>Proyecto(s):</b></p>
						<p>{{ getProjectsName(form.projects) }}</p>
						<p><b>Empresa:</b> {{ form.enterprise.name }}</p>
					</div>
				</div>
			</div>
			<div class="satisfaction-form-preview-card">
				<div
					v-for="(question, index) in form.questions"
					:key="question.id"
					class="question"
				>
					<p>{{ (index += 1) }}.- {{ question.name }}</p>
					<textarea
						v-if="question.question_type == 'text'"
						cols="30"
						rows="2"
						class="response"
					></textarea>
					<div v-if="question.question_type == 'multiple'">
						<div
							v-for="option in question.options"
							:key="option.id"
							class="satisfaction-form-question-option response"
						>
							<input
								:id="option.id"
								type="radio"
								:name="`${question}${question.id} `"
							/>
							<label :for="option.id"> {{ option.name }}</label>
						</div>
					</div>
					<div v-if="question.question_type == 'polar'">
						<div class="satisfaction-form-question-option response">
							<input
								id="yes"
								type="radio"
								:name="`${question.name}${question.id} `"
							/>
							<label for="yes"> Sí</label>
						</div>
						<div class="satisfaction-form-question-option response">
							<input
								id="no"
								type="radio"
								:name="`${question.name}${question.id} `"
							/>
							<label for="no"> No</label>
						</div>
					</div>
					<div
						v-if="question.question_type == 'scale'"
						class="scale-container response"
					>
						<div
							v-if="question['options'][0].emoji == null"
							class="scale-element"
						>
							<font-awesome-icon
								class="icon scale-element"
								:icon="['fal', 'circle']"
							/>
							<br />
							0
						</div>
						<template v-for="n in question['options'][0].scale">
							<font-awesome-icon
								v-if="question['options'][0].emoji != null"
								:key="`emoji-${n}`"
								style="font-size: 18px"
								class="icon scale-element"
								:icon="['fal', `${question['options'][0].emoji}`]"
							/>
							<div v-else :key="`no-emoji-${n}`" class="scale-element">
								<font-awesome-icon
									class="icon scale-element"
									:icon="['fal', 'circle']"
								/>
								<br />
								{{ n }}
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'SatisfactionForm',
	prop: {
		id: { type: Number, default: null },
	},
	data() {
		return {
			form: {
				name: null,
				description: null,
				project: {
					id: null,
					name: null,
				},
				questions: [],
				enterprise: {
					id: null,
					name: null,
				},
			},
		}
	},
	computed: {
		...mapGetters('forms', ['getFormById']),
	},
	created() {
		this.form = this.getFormById()
	},

	methods: {
		getProjectsName(projects) {
			let allProjects = ''
			projects.map((project) => {
				allProjects += `${project.name} `
			})
			return allProjects
		},
	},
}
</script>
<style lang="scss" scoped>
.scale-container {
	display: flex;
	padding-top: 0px !important;
}
.response {
	padding-left: 25px !important;
}
.satisfaction-form-notification-container {
	text-align: unset;
}
.preview-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-right: 25px;
	margin-left: 25px;
	margin-bottom: 10px;
}
.satisfaction-form-main-card {
	padding-top: 40px;
}
.satisfaction-form-modal-container {
	padding-bottom: 40px;
}
</style>
