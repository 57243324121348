<template>
	<div style="width: 100%; min-height: 100vh; position: relative">
		<div class="satisfaction-form-main-card">
			<div class="header">
				<font-awesome-icon
					class="satisfaction-form-input-icon"
					:icon="['fas', 'arrow-circle-left']"
					@click="backToForm"
				/>
				<input
					id="inputName"
					v-model="name"
					type="text"
					placeholder="Nombre de la encuesta"
				/>
				<font-awesome-icon
					class="satisfaction-form-input-right-icon"
					:icon="['fas', 'edit']"
					@click="focusName"
				/>
			</div>
			<div class="satisfaction-form-form-general-data">
				<div class="satisfaction-form-general-row">
					<input
						v-model="description"
						type="text"
						class="satisfaction-form-main-input"
						placeholder="Descripción de la encuesta"
					/>
				</div>
				<div class="satisfaction-form-general-row">
					<div class="satisfaction-form-general-col">
						<select
							id=""
							v-model="enterprise"
							style="margin-right: 5px"
							class="satisfaction-form-general-select"
							name="Enterprise"
							@change="resetProject()"
						>
							<option
								:value="null"
								hidden
								:selected="`${mode == 'create' ? 'selected' : ''}`"
							>
								Empresa
							</option>
							<option
								v-for="fetchedEnterprise in getFetchedEnterprises"
								:key="fetchedEnterprise.id"
								:value="fetchedEnterprise.id"
							>
								{{ fetchedEnterprise.name }}
							</option>
						</select>
						<MultipleSelect
							v-model="projects"
							placeholder="Proyecto"
							multiple
							:data="projectsByEnterprise"
						/>
					</div>
				</div>
				<div class="satisfaction-form-general-row">
					<select
						id=""
						v-model="event"
						style="margin-right: 5px"
						class="satisfaction-form-general-select"
						name="Event"
					>
						<option
							:value="null"
							hidden
							:selected="`${mode == 'create' ? 'selected' : ''}`"
						>
							Evento
						</option>
						<option
							v-for="fetchedEvent in getFetchedEvents"
							:key="fetchedEvent.id"
							:value="fetchedEvent.id"
						>
							{{ fetchedEvent.name }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<question
			v-for="question in questions"
			:id="question.id"
			:key="question.id"
			:name="question.name"
			:type="question.type"
			:required="question.required"
			:options="question.options"
			:scale="question.scale"
			:emoji="question.emoji"
			:status="question.status"
			@delete-question="deleteQuestion"
			@duplicate-question="duplicateQuestion"
			@update-question="updateQuestion"
		/>
		<div class="satisfaction-form-centered-container" style="text-align: end">
			<button
				class="satisfaction-form-btn-rounded"
				style="margin-bottom: 20px; margin: top: 5px;"
				@click="newQuestion()"
			>
				<span>
					<font-awesome-icon style="color: white" :icon="['fas', 'plus']" />
				</span>
			</button>
			<br />
			<button class="satisfaction-form-btn-primary text-bold" @click="saveForm">
				<span>
					<font-awesome-icon
						style="color: white; margin-right: 2px"
						:icon="['fal', 'download']"
					/>
				</span>
				{{ mode == 'create' ? 'Guardar' : 'Actualizar' }}
			</button>
		</div>
		<notification
			v-if="showNotification || getShowMessageStatus"
			header="Atención"
			text="¿Está seguro que desea volver?, el avance se perderá"
			type="validation"
			:icon="['fal', 'info-circle']"
			@user-response="checkUserResponse"
			@close-alert="close"
		/>
	</div>
</template>

<script>
import Question from '@/components/Question.vue'
import Notification from '@/components/Notification.vue'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

export default {
	name: 'ManageForm',
	components: {
		Question,
		Notification,
	},
	props: {
		mode: { type: String, default: 'create' },
	},
	data() {
		return {
			id: null,
			name: null,
			description: null,
			projects: [],
			questions: [],
			enterprise: null,
			event: null,
			showNotification: false,
		}
	},
	computed: {
		...mapGetters('forms', ['getFormById']),
		...mapGetters('general', [
			'getFetchedEnterprises',
			'getFetchedProjects',
			'getFetchedEvents',
			'getShowMessageStatus',
		]),
		...mapState('general', ['propertyNames']),
		projectsByEnterprise() {
			return this.getFetchedProjects
				.filter(
					(project) =>
						project.enterprise[this.propertyNames.id] == this.enterprise,
				)
				.map((project) => {
					return {
						text: project.name,
						value: {
							id: project.id,
							name: project.name,
						},
					}
				})
		},
	},
	mounted() {
		if (this.mode == 'create') this.newQuestion()
		else this.setForm()
	},
	methods: {
		...mapActions('forms', ['sendForm', 'updateForm']),
		...mapMutations('general', ['setshowMessageStatus']),
		newQuestion() {
			const question = {
				id: Date.now(),
				name: '',
				options: [],
				type: 'text',
				required: true,
				scale: null,
				emoji: null,
				status: 'created',
			}
			this.questions.push(question)
		},
		deleteQuestion(id) {
			const index = this.questions.findIndex((question) => question.id == id)
			let question = this.questions.find((question) => question.id == id)
			if (question.status != 'created') {
				question['status'] = 'deleted'
				this.questions.splice(index, 1, question)
			} else {
				this.questions.splice(index, 1)
			}
		},
		duplicateQuestion(id) {
			let question = this.questions.find((question) => question.id == id)
			question = { ...question, ...{ id: Date.now() } }
			this.questions.push(question)
		},
		updateQuestion(data) {
			const index = this.questions.findIndex(
				(question) => question.id == data.id,
			)
			let question = this.questions[index]
			question[data.propertyName] = data.propertyValue
			if (question.status == null) question['status'] = 'updated'
			this.questions.splice(index, 1, question)
		},
		backToForm() {
			this.showNotification = true
		},
		checkUserResponse(response) {
			if (response) {
				this.$emit('back-to-form')
			} else {
				this.showNotification = false
			}
		},
		saveForm() {
			const data = {
				id: this.id,
				name: this.name,
				description: this.description,
				projects: this.projects,
				enterprise: this.getFetchedEnterprises.find(
					(enterprise) => enterprise.id == this.enterprise,
				),
				questions: this.questions,
				event: this.getFetchedEvents.find((event) => event.id == this.event),
			}
			if (this.mode == 'create') this.sendForm(data)
			else this.updateForm(data)
		},
		setForm() {
			const form = this.getFormById()
			const formQuestions = form.questions.map((question) => {
				let formattedQuestion = {
					id: question.id,
					name: question.name,
					type: question.question_type,
					required: question.required,
					scale: null,
					emoji: null,
					status: null,
				}
				let formattedOptions = []
				if (question.question_type == 'scale') {
					formattedQuestion.scale = question.options[0]['scale']
					formattedQuestion.scale = question.options[0]['emoji']
				} else {
					formattedOptions = question.options.map((option) => option['name'])
				}
				formattedQuestion = {
					...formattedQuestion,
					...{ options: formattedOptions },
				}
				return formattedQuestion
			})
			this.id = form.id
			this.name = form.name
			this.description = form.description
			this.enterprise = form.enterprise.id
			this.projects = form.projects.map((project) => {
				return { id: project.project_id, name: project.name }
			})
			this.questions = formQuestions
			this.event = form.event.id
		},
		resetProject() {
			this.project = null
		},
		focusName() {
			document.getElementById('inputName').focus()
		},
		close() {
			this.setshowMessageStatus(false)
			this.$emit('back-to-form')
		},
	},
}
</script>
<style lang="scss">
select {
	color: grey !important;
	> option {
		color: black !important;
	}
}
.ms-parent {
	width: 100% !important;
	> button {
		min-height: 30px !important;
		> span {
			color: grey !important;
		}
	}
	> .ms-drop {
		text-align: initial !important;
	}
}
</style>
