<template>
	<div style="padding: 10px">
		<forms-panel v-if="mode == 'editor'" />
		<answer-form v-else-if="mode == 'answer'" />
	</div>
</template>

<script>
import FormsPanel from '@/views/FormsPanel.vue'
import { mapState, mapActions } from 'vuex'
import AnswerForm from '@/views/AnswerForm.vue'

export default {
	name: 'Main',
	components: {
		FormsPanel,
		AnswerForm,
	},
	data() {
		return {}
	},
	computed: {
		...mapState('general', ['mode']),
	},
	async created() {
		if (this.mode != 'answer') {
			this.fetchProjects()
			this.fetchEvents()
		}
	},
	methods: {
		...mapActions('general', ['fetchProjects', 'fetchEvents']),
	},
}
</script>
